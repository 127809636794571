import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';

import * as serviceWorker from './serviceWorker';

let uptime: number | undefined;
let downtime: string | undefined;

const url = (window && window.location && window.location.pathname) || '';
const urlPaths = url.split('/');

urlPaths.shift(); //First / remove

if (urlPaths[0] === 'routes') {
    
    urlPaths.shift(); //'routes' remove

    if (urlPaths.length > 1) {
    
        if (urlPaths[0] === 'uptime') {
            uptime = Number.parseFloat(urlPaths[1]) || undefined;
        }
        else if (urlPaths[0] === 'downtime') {
            downtime = urlPaths[1];
        }
    }
}

ReactDOM.render(<App uptime={uptime} downtime={downtime} />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
